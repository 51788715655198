 /* Customizable Area Start */
.firstloginMain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 393px;
  height: 100vh;
  padding: 40px;
}
.firstLoginTop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.LeftArrow {
  position: absolute;
  left: 0px;
  display: none;
  @media (max-width: 960px) {
    margin: 51px 0px 0px 33px;
    display: flex;
    position: absolute;
    justify-content: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
.firstLoginlogo {
  width: 100px;
  height: 31.822px;
}
.firstLogintitleContainer{
    margin-top: 80px;
}
.firstLogintitle {
  color: #363636;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  font-family: 'Nunito', sans-serif;
}
.firstLoginSubtitle {
  color: #666666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 10px;
}
.firstLoginForm {
  margin: 120px 0px;
  width: 100%;
}
.firstLoginDropDown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 300px;
  border-radius: 10px;
  border: 2px solid #e6e6e6;
  background: #f7f7f7;
  padding: 0px 10px;
  cursor: pointer;
}
.firstLoginDropDownActive{
  
  border: 2px solid #999;

}
.firstLoginDropDownTitle {
  width: 100%;
  color: #999;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  margin-left: 10px;
}
.firstLoginDropDownTitleActive {
  width: 100%;
  color: #363636;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  margin-left: 10px;
}
.firstLoginDropStartIcon,
.firstLoginDropEndIcon {
  width: 18px;
  height: 18px;
}
.firstLoginOptionContainer {
  width: 300px;
  height: 175px;
  position: absolute;
  border-radius: 10px;
  border: 2px solid #999;
  background: #f7f7f7;
  padding: 10px;
  margin-top: 55px;
  z-index: 99999;
}
.firstLoginSearchContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0px 10px;
  background-color: #E6E6E6;
  border-radius: 8px;
}
.searchIcon {
  width: 14px;
  height: 14px;
}
.firstLoinsearch {
  height: 32px;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 17px;
  font-family: 'Quicksand', sans-serif;
}
.firstLoginList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scrollbar-width: none;
}
.firstLoginListItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0px;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
}
.firstLoginListItemOption {
  color: var(--primary-blue-dark-gray, #363636);
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}
.firstLoginListItemTime {
  color: var(--primary-neutrals-warm-gray-400, #999);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; 
}
.firstLoginDropDownBtnContaine{
  width: 260px;
height: 40px;
flex-shrink: 0;
border-radius: 12px;
background:  #36BFFF;
margin: auto;
color: white;
font-family: 'Nunito', sans-serif;
border: none;
box-shadow: 0px 3px 0px 3px #01AEFF;
font-size: 16px;
cursor: pointer;
font-weight: 700;
}

.firstLoginDropDownBtnContaine:disabled {
  background-color: rgb(230, 230, 230);
  cursor: not-allowed;
  color: rgb(179, 179, 179);
  box-shadow: unset;
  }

.countriesName{
  font-family: 'Nunito', sans-serif;
}
.errorMessage{
  font-size: 15px ;
  color: red;
}
/*  Customizable Area End */
