.notificationMainScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.inAppNotificationMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.inAppNotificationMainCenterCard {
  width: 431px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #00aeff;
  padding: 20px;
  border-radius: 0px 0px 20px 0px;
}

.inAppNotificationMainCenterCardInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets//centerCardBg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.notificationCenter{
  width: 100%;
  background-color: #00aeff;
}

.notificationTopMenu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 47px;
  border-radius: 20px 0px 0px 0px;
  background-color: white;
}

.notificationTopMenuContainer {
  width: 33px;
  height: 33px;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.notificationTopMenuContainer:hover {
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.notificationTopMenuContainerActive {
  width: 33px;
  height: 33px;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.notificationTopMenuList {
  position: absolute;
  width: 223px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #ccc;
  background: #fff;
  top: 40px;
  right: 12px;
  padding: 15.5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 0px;
  z-index: 999;
}

.notificationTopMenuListItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 9.5px;
  cursor: pointer;
}

.notificationTopMenuListItemImage {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.notificationTopMenuListItemTitle {
  width: 100%;
  height: 13px;
  color: var(--primary-neutrals-warm-gray-500, #7f7f7f);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.notificationTopMenuIcon {
  width: 21.667px;
  height: 5px;
  flex-shrink: 0;
}

.allNotificationContainer {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
  scrollbar-width: none;
  padding:0px 47px;
  background-color: white;
}

.notificationCard {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  display: flex;
  border-radius: 10px;
  gap: 19px;
  align-items: center;
  margin: 0px 10px 8px 10px;
  background: var(--primary-neutrals-warm-gray-50, #f2f2f2);
}

.notificationCardMainMarkAsRead {
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.cardIcon{
  width: 34px;
  height: 34px;
}

.notificationCardDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.notificationHeading {
  color: #363636;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.notificationPara {
  color: #363636;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.notificationParaMoreBtn {
  width: 41px;
  height: 13px;
  flex-shrink: 0;
  color: #62cdff;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
}

.notificationTime {
  font-size: 14px;
  font-weight: 500px;
  font-family: "Quicksand", sans-serif;
  color: var(--primary-neutrals-warm-gray-300, #b3b3b3);
}

.notificationCardMarkasReadActive {
  width: 70px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.notificationCardMarkasRead {
  width: 70px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 10px;
}

.notificationCardDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 93%;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--secondary-red, #f96855);
}

.notificationCardDeleteIcon {
  width: 29px;
  height: 29px;
  margin: auto;
}

.renderMarkAsReadTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 57px;
  height: 33px;
  width: 100%;
  border-radius: 20px 0px 0px 0px;
  background-color: white;
}

.renderMarkAsReadTopContainer,
.renderMarkAsReadDeleteContainer,
.renderMarkAsReadCancelContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8.9px;
  cursor: pointer;
}

.renderMarkAsReadIcon1 {
  width: 14.911px;
  height: 9px;
  flex-shrink: 0;
}

.renderMarkAsReadText {
  height: 13px;
  color: var(--primary-blue-blue-shadows, #00aeff);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadTextDisable {
  height: 13px;
  color: #CCEFFF;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadDeleteText {
  width: 51px;
  height: 13px;
  flex-shrink: 0;
  color: var(--secondary-red, #f96855);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadDeleteTextDisable {
  width: 51px;
  height: 13px;
  flex-shrink: 0;
  color: #FEE1DD;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadCancelText {
  width: 51px;
  height: 13px;
  flex-shrink: 0;
  color: var(--secondary-red, #999);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.swipeable-list-item__content--return {
  height: min-content !important;
}

.swipeableList.swipeable-list-item__content {
  height: unset;
}

.swipeableList.swipeable-list {
  overflow-y: visible !important;
}

.cardCheckbox.MuiIconButton-colorSecondary {
  stroke: #e6e6e6 !important;
  stroke-width: 1 !important;
}

.cardCheckbox.MuiCheckbox-colorSecondary.Mui-checked {
  stroke: #36bfff !important;
  stroke-linejoin: round !important;
  stroke-linecap: round !important;
  stroke-width: 1 !important;
  color: white !important;
}

.modalDeleteBtn {
  margin-right: 26px;
  width: 110px;
  height: 40px;
  border-radius: 12px;
  background: #f96855;
  color: #fff;
  border: none;
  box-shadow: 0px 2px 0px 2px #ea5a47;
}

.deleteModalCancelBtn {
  margin-left: 26px;
  width: 110px;
  height: 44px;
  border-radius: 12px;
  background: #fff;
  color: #cccccc;
  border: none;
  box-shadow: 0px 2px 0px 0px #cccccc;
}

.deleteModalBtnContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
}

.deleteModalWarningText {
  width: 222px;
  height: 40px;
  color: #666;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  margin-top: 10px;
}

.deleteModalTitle {
  width: 260px;
  height: 19px;
  color: #363636;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin: 17px auto 0px auto;
}

.deleteModalImage {
  width: 39.153px;
  height: 39.153px;
}

.deleteModalInnerBox {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.deleteModalBox {
  width: 292px;
  height: 281px;
  border-radius: 12px;
  border: 2px solid #e6e6e6;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 15% auto;
  background: #f7f7f7;
}

.drawerImage1 {
  width: 100px;
  height: 31.822px;
  margin: 66px 0 66px 18px;
}

.drawerImage2{
  width:24px;
  height:auto
}

.drawerImage3{
  width:24px;
  height:auto;
}

.swipeable-list{
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column !important;
}

@media screen and (max-width:959px) {
  .inAppNotificationMainCenterCard{
    display: none;
  }
}

@media screen and (max-width:1300px) {
  .notificationTopMenu, .allNotificationContainer{
    padding:10px 20px;
  }
  .renderMarkAsReadTop{
    padding: 27px 30px;
  }
}

@media screen and (max-width:576px) {
  .notificationTopMenu {
    padding: 12px 5px;
  }
  .renderMarkAsReadTop {
    padding: 27px 30px;
  }
}