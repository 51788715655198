.NotificationDetails{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.notificationDetailWrapper{
  padding: 0px 57px;
  height: 100%;
  width: 100%;
}

.notificationDetailContainer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0px auto 30px auto;
  justify-content: flex-start;
  padding: 0 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
  scrollbar-width: none;
  position: relative;
  background-color: #f2f2f2;
}

.NotificationDetailsmain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotificationDetailsMain {
  width: 100%;
  margin: auto;
}

.detailsContent > *{
  color: var(--primary-blue-dark-gray, #363636);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.detailsContent > p > strong {
  font-family: "Quicksand", sans-serif;
}

.detailsContent > p > a > strong {
  font-family: "Quicksand", sans-serif;
}

.notificationDetailTopIcon {
  width: 61px;
  height: 61px;
  margin: 35px auto 26px auto;
}

.notificationTopMenuListDetail {
  position: absolute;
  width: 223px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #ccc;
  background: #fff;
  top: 40px;
  right: 12px;
  padding: 15.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 0px;
  z-index: 999;
}

.notificationTopMenuListDetailItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 9.5px;
  cursor: pointer;
}

.notificationTopMenuListDetailItemImage {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.notificationTopMenuListDetailItemTitle {
  width: 100%;
  height: 13px;
  color: var(--primary-neutrals-warm-gray-500, #7f7f7f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.notificationDetailContainerHeading {
  color: var(--primary-blue-dark-gray, #363636);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; 
  margin: 0px;
}

.notificationDetailPara ,.notificationBestRegards{
  color: var(--primary-blue-dark-gray, #363636);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0px;
}

.notificationDetailParaDark{
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
}

.notificationDetailParaBlue{
  color: #00AEFF;
  font-family: "Quicksand", sans-serif;
}

.detailContentButton{
  width: 260px;
  height: 40px;
  flex-shrink: 0;
  margin: 30px auto;
  border-radius: 12px;
  background: var(--primary-blue-blue-primary-dark, #36BFFF);
  box-shadow: 0px 4px 0px 0px #00AEFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
}

.notificationDetailFooterIcon{
  width: 130px;
  height: 22px;
  margin: auto;
}

.notificationDetailsTime{
  width: 260px;
  height: 12px;
  color: #B3B3B3;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  position: absolute;
  bottom: 18px;
}

@media screen and (max-width :959px) {
  .notificationDetailWrapper{
    padding: 0px 20px;
  }
}