@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
 /* Customizable Area Start */
.firstloginMain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 393px;
  height: 100vh;
  padding: 40px;
}
.firstLoginTop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.LeftArrow {
  position: absolute;
  left: 0px;
  display: none;
  @media (max-width: 960px) {
    margin: 51px 0px 0px 33px;
    display: flex;
    position: absolute;
    justify-content: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
.firstLoginlogo {
  width: 100px;
  height: 31.822px;
}
.firstLogintitleContainer{
    margin-top: 80px;
}
.firstLogintitle {
  color: #363636;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  font-family: 'Nunito', sans-serif;
}
.firstLoginSubtitle {
  color: #666666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 10px;
}
.firstLoginForm {
  margin: 120px 0px;
  width: 100%;
}
.firstLoginDropDown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 300px;
  border-radius: 10px;
  border: 2px solid #e6e6e6;
  background: #f7f7f7;
  padding: 0px 10px;
  cursor: pointer;
}
.firstLoginDropDownActive{
  
  border: 2px solid #999;

}
.firstLoginDropDownTitle {
  width: 100%;
  color: #999;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  margin-left: 10px;
}
.firstLoginDropDownTitleActive {
  width: 100%;
  color: #363636;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
    font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  margin-left: 10px;
}
.firstLoginDropStartIcon,
.firstLoginDropEndIcon {
  width: 18px;
  height: 18px;
}
.firstLoginOptionContainer {
  width: 300px;
  height: 175px;
  position: absolute;
  border-radius: 10px;
  border: 2px solid #999;
  background: #f7f7f7;
  padding: 10px;
  margin-top: 55px;
  z-index: 99999;
}
.firstLoginSearchContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 0px 10px;
  background-color: #E6E6E6;
  border-radius: 8px;
}
.searchIcon {
  width: 14px;
  height: 14px;
}
.firstLoinsearch {
  height: 32px;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 17px;
  font-family: 'Quicksand', sans-serif;
}
.firstLoginList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scrollbar-width: none;
}
.firstLoginListItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0px;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
}
.firstLoginListItemOption {
  color: var(--primary-blue-dark-gray, #363636);
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}
.firstLoginListItemTime {
  color: var(--primary-neutrals-warm-gray-400, #999);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; 
}
.firstLoginDropDownBtnContaine{
  width: 260px;
height: 40px;
flex-shrink: 0;
border-radius: 12px;
background:  #36BFFF;
margin: auto;
color: white;
font-family: 'Nunito', sans-serif;
border: none;
box-shadow: 0px 3px 0px 3px #01AEFF;
font-size: 16px;
cursor: pointer;
font-weight: 700;
}

.firstLoginDropDownBtnContaine:disabled {
  background-color: rgb(230, 230, 230);
  cursor: not-allowed;
  color: rgb(179, 179, 179);
  box-shadow: unset;
  }

.countriesName{
  font-family: 'Nunito', sans-serif;
}
.errorMessage{
  font-size: 15px ;
  color: red;
}
/*  Customizable Area End */

.firstLoginTop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.LeftArrow {
  position: absolute;
  left: 0px;
}
.firstLoginlogo {
  width: 100px;
  height: 31.822px;
}
.firstLogintitleContainer {
  margin-top: 35px;
}
.firstLogintitle {
  color: #363636;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  font-family: 'Nunito', sans-serif;
}
.firstLoginSubtitle {
  color: #666666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 10px;
}
.firstLoginDropDownBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 80px auto 0px auto;

}
.firstLoginDropDownBtn {
  width: 260px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #36bfff;
  color: white;
  font-family: 'Nunito', sans-serif;
  border: none;
  font-size: 16px;
  box-shadow: 0px 3px 0px 3px #01AEFF;
  font-weight: 700;
}
.selectAvatar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.avatarSelection {
  position: relative;
  border-radius: 50%;
  width: 260px;
  height: 260px;
  margin: 79px auto 0px auto;
}

.circle {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  height: 58px;
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.circleActive {
  border-radius: 10px;
  background-color: #f2f2f2;
}

.mainImage {
  width: 105.206px;
  height: 133px;
}

.circle:nth-child(1) {
  transform: translate(125px, 8px);
}

.circle:nth-child(2) {
  transform: translate(87px, 87px);
}

.circle:nth-child(3) {
  transform: translate(0px, 125px);
}

.circle:nth-child(4) {
  transform: translate(-87px, 87px);
}

.circle:nth-child(5) {
  transform: translate(-134px, 0px);
}
.circle:nth-child(6) {
  transform: translate(-110px, -80px);
}
.circle:nth-child(7) {
  transform: translate(0px, -130px);
}
.circle:nth-child(8) {
  transform: translate(110px, -75px);
}
.circle:nth-child(9) {
  transform: translate(-22px, -38px);
}
.countryName{
  font-family: 'Nunito', sans-serif;
}
.notificationMainScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.inAppNotificationMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.inAppNotificationMainCenterCard {
  width: 431px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #00aeff;
  padding: 20px;
  border-radius: 0px 0px 20px 0px;
}

.inAppNotificationMainCenterCardInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/centerCardBg.e28adeed.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.notificationCenter{
  width: 100%;
  background-color: #00aeff;
}

.notificationTopMenu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 47px;
  border-radius: 20px 0px 0px 0px;
  background-color: white;
}

.notificationTopMenuContainer {
  width: 33px;
  height: 33px;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.notificationTopMenuContainer:hover {
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.notificationTopMenuContainerActive {
  width: 33px;
  height: 33px;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.notificationTopMenuList {
  position: absolute;
  width: 223px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #ccc;
  background: #fff;
  top: 40px;
  right: 12px;
  padding: 15.5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  margin: 0px;
  z-index: 999;
}

.notificationTopMenuListItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 9.5px;
  gap: 9.5px;
  cursor: pointer;
}

.notificationTopMenuListItemImage {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.notificationTopMenuListItemTitle {
  width: 100%;
  height: 13px;
  color: var(--primary-neutrals-warm-gray-500, #7f7f7f);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.notificationTopMenuIcon {
  width: 21.667px;
  height: 5px;
  flex-shrink: 0;
}

.allNotificationContainer {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  scrollbar-width: none;
  padding:0px 47px;
  background-color: white;
}

.notificationCard {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  display: flex;
  border-radius: 10px;
  grid-gap: 19px;
  gap: 19px;
  align-items: center;
  margin: 0px 10px 8px 10px;
  background: var(--primary-neutrals-warm-gray-50, #f2f2f2);
}

.notificationCardMainMarkAsRead {
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.cardIcon{
  width: 34px;
  height: 34px;
}

.notificationCardDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.notificationHeading {
  color: #363636;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.notificationPara {
  color: #363636;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.notificationParaMoreBtn {
  width: 41px;
  height: 13px;
  flex-shrink: 0;
  color: #62cdff;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
}

.notificationTime {
  font-size: 14px;
  font-weight: 500px;
  font-family: "Quicksand", sans-serif;
  color: var(--primary-neutrals-warm-gray-300, #b3b3b3);
}

.notificationCardMarkasReadActive {
  width: 70px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary-blue-blue-primary-light, #c9eeff);
}

.notificationCardMarkasRead {
  width: 70px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 10px;
}

.notificationCardDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 93%;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--secondary-red, #f96855);
}

.notificationCardDeleteIcon {
  width: 29px;
  height: 29px;
  margin: auto;
}

.renderMarkAsReadTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 57px;
  height: 33px;
  width: 100%;
  border-radius: 20px 0px 0px 0px;
  background-color: white;
}

.renderMarkAsReadTopContainer,
.renderMarkAsReadDeleteContainer,
.renderMarkAsReadCancelContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8.9px;
  gap: 8.9px;
  cursor: pointer;
}

.renderMarkAsReadIcon1 {
  width: 14.911px;
  height: 9px;
  flex-shrink: 0;
}

.renderMarkAsReadText {
  height: 13px;
  color: var(--primary-blue-blue-shadows, #00aeff);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadTextDisable {
  height: 13px;
  color: #CCEFFF;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadDeleteText {
  width: 51px;
  height: 13px;
  flex-shrink: 0;
  color: var(--secondary-red, #f96855);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadDeleteTextDisable {
  width: 51px;
  height: 13px;
  flex-shrink: 0;
  color: #FEE1DD;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.renderMarkAsReadCancelText {
  width: 51px;
  height: 13px;
  flex-shrink: 0;
  color: var(--secondary-red, #999);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.swipeable-list-item__content--return {
  height: -moz-min-content !important;
  height: min-content !important;
}

.swipeableList.swipeable-list-item__content {
  height: unset;
}

.swipeableList.swipeable-list {
  overflow-y: visible !important;
}

.cardCheckbox.MuiIconButton-colorSecondary {
  stroke: #e6e6e6 !important;
  stroke-width: 1 !important;
}

.cardCheckbox.MuiCheckbox-colorSecondary.Mui-checked {
  stroke: #36bfff !important;
  stroke-linejoin: round !important;
  stroke-linecap: round !important;
  stroke-width: 1 !important;
  color: white !important;
}

.modalDeleteBtn {
  margin-right: 26px;
  width: 110px;
  height: 40px;
  border-radius: 12px;
  background: #f96855;
  color: #fff;
  border: none;
  box-shadow: 0px 2px 0px 2px #ea5a47;
}

.deleteModalCancelBtn {
  margin-left: 26px;
  width: 110px;
  height: 44px;
  border-radius: 12px;
  background: #fff;
  color: #cccccc;
  border: none;
  box-shadow: 0px 2px 0px 0px #cccccc;
}

.deleteModalBtnContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
}

.deleteModalWarningText {
  width: 222px;
  height: 40px;
  color: #666;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  margin-top: 10px;
}

.deleteModalTitle {
  width: 260px;
  height: 19px;
  color: #363636;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin: 17px auto 0px auto;
}

.deleteModalImage {
  width: 39.153px;
  height: 39.153px;
}

.deleteModalInnerBox {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.deleteModalBox {
  width: 292px;
  height: 281px;
  border-radius: 12px;
  border: 2px solid #e6e6e6;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 15% auto;
  background: #f7f7f7;
}

.drawerImage1 {
  width: 100px;
  height: 31.822px;
  margin: 66px 0 66px 18px;
}

.drawerImage2{
  width:24px;
  height:auto
}

.drawerImage3{
  width:24px;
  height:auto;
}

.swipeable-list{
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column !important;
}

@media screen and (max-width:959px) {
  .inAppNotificationMainCenterCard{
    display: none;
  }
}

@media screen and (max-width:1300px) {
  .notificationTopMenu, .allNotificationContainer{
    padding:10px 20px;
  }
  .renderMarkAsReadTop{
    padding: 27px 30px;
  }
}

@media screen and (max-width:576px) {
  .notificationTopMenu {
    padding: 12px 5px;
  }
  .renderMarkAsReadTop {
    padding: 27px 30px;
  }
}
.HeaderMain{
  width: 100%;
}

.notificationTop {
  width: 100%;
  height: 111px;
  border-radius: 0px 0px 20px 0px;
  background: #01aeff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notificationTopContent {
  width: 100%;
  height: 45px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0.176px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.notificationArrowIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  position: absolute;
  left: 38.3px;
  cursor: pointer;
}

.notificationTitle {
  margin: 0px;
  width: -moz-min-content;
  width: min-content;
  height: 45px;
  flex-shrink: 0;
  color: var(--primary-blue-white, #fff);
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px; /* 118.182% */
  letter-spacing: 0.176px;
}

.notificationTopBgImage {
  position: absolute;
  bottom: 0px;
  right: 5%;
}

.footerMain {
  width: 393px;
  height: 69px;
  display: flex;
  justify-content: space-evenly;
  border-radius: 20px 20px 0px 0px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.08);
}

.NotificationDetails{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.notificationDetailWrapper{
  padding: 0px 57px;
  height: 100%;
  width: 100%;
}

.notificationDetailContainer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0px auto 30px auto;
  justify-content: flex-start;
  padding: 0 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  scrollbar-width: none;
  position: relative;
  background-color: #f2f2f2;
}

.NotificationDetailsmain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotificationDetailsMain {
  width: 100%;
  margin: auto;
}

.detailsContent > *{
  color: var(--primary-blue-dark-gray, #363636);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.detailsContent > p > strong {
  font-family: "Quicksand", sans-serif;
}

.detailsContent > p > a > strong {
  font-family: "Quicksand", sans-serif;
}

.notificationDetailTopIcon {
  width: 61px;
  height: 61px;
  margin: 35px auto 26px auto;
}

.notificationTopMenuListDetail {
  position: absolute;
  width: 223px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #ccc;
  background: #fff;
  top: 40px;
  right: 12px;
  padding: 15.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  margin: 0px;
  z-index: 999;
}

.notificationTopMenuListDetailItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 9.5px;
  gap: 9.5px;
  cursor: pointer;
}

.notificationTopMenuListDetailItemImage {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.notificationTopMenuListDetailItemTitle {
  width: 100%;
  height: 13px;
  color: var(--primary-neutrals-warm-gray-500, #7f7f7f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.notificationDetailContainerHeading {
  color: var(--primary-blue-dark-gray, #363636);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; 
  margin: 0px;
}

.notificationDetailPara ,.notificationBestRegards{
  color: var(--primary-blue-dark-gray, #363636);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0px;
}

.notificationDetailParaDark{
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
}

.notificationDetailParaBlue{
  color: #00AEFF;
  font-family: "Quicksand", sans-serif;
}

.detailContentButton{
  width: 260px;
  height: 40px;
  flex-shrink: 0;
  margin: 30px auto;
  border-radius: 12px;
  background: var(--primary-blue-blue-primary-dark, #36BFFF);
  box-shadow: 0px 4px 0px 0px #00AEFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
}

.notificationDetailFooterIcon{
  width: 130px;
  height: 22px;
  margin: auto;
}

.notificationDetailsTime{
  width: 260px;
  height: 12px;
  color: #B3B3B3;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  position: absolute;
  bottom: 18px;
}

@media screen and (max-width :959px) {
  .notificationDetailWrapper{
    padding: 0px 20px;
  }
}
.deleteNotification {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.InappDeleteNotification {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationTopMenuListDelete {
  position: absolute;
  width: 223px;
  height: 111px;
  border-radius: 10px;
  border: 2px solid #ccc;
  background: #fff;
  top: 40px;
  right: 12px;
  padding: 15.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  margin: 0px;
  z-index: 999;
}

.notificationTopMenuListDeleteItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 9.5px;
  gap: 9.5px;
  cursor: pointer;
}

.notificationTopMenuListDeleteItemImage {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.notificationTopMenuListDeleteItemTitle {
  width: 100%;
  height: 13px;
  color: #7f7f7f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.allNotificationContainer {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  scrollbar-width: none;
}

.deleteNotificationCard {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  display: flex;
  border-radius: 10px;
  grid-gap: 19px;
  gap: 19px;
  align-items: center;
  margin: 0px auto 8px auto;
  background: #f2f2f2;
}

.deleteNotificationCardDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.deleteNotificationCardImage{
  width:29px;
  height: 29px;
}

.deleteNotificationHeading {
  color: #7f7f7f;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.deleteNotificationPara {
  color: #7f7f7f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.deleteNotificationTime {
  font-size: 14px;
  font-weight: 500px;
  font-family: "Quicksand", sans-serif;
  color: #b3b3b3;
}

.NotificationSubHeading {
  width: 100%;
  height: 47px;
  flex-shrink: 0;
  color: #7f7f7f;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin: 0px auto 30px auto;
  padding: 0px 47px;
}

.cardCheckbox.MuiIconButton-colorSecondary {
  stroke: #e6e6e6 !important;
  stroke-width: 1 !important;
}

.cardCheckbox.MuiCheckbox-colorSecondary.Mui-checked {
  stroke: #36bfff !important;
  stroke-linejoin: round !important;
  stroke-linecap: round !important;
  stroke-width: 2 !important;
  color: white !important;
}

.modalDeleteBtn {
  margin-right: 26px;
  width: 110px;
  height: 40px;
  border-radius: 12px;
  background: #f96855;
  color: #fff;
}

.deleteModalCancelBtn {
  margin-left: 26px;
  width: 110px;
  height: 44px;
  border-radius: 12px;
  border: 2px solid #ccc;
  background: #fff;
  color: #cccccc;
}

.deleteModalBtnContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
}

.deleteModalWarningText {
  width: 222px;
  height: 40px;
  color: #666;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  margin-top: 10px;
}

.deleteModalTitle {
  width: 260px;
  height: 19px;
  color: #363636;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin: 17px auto 0px auto;
}

.deleteModalImage {
  width: 39.153px;
  height: 39.153px;
}

.deleteModalInnerBox {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.deleteModalBox {
  width: 292px;
  height: 281px;
  border-radius: 12px;
  border: 2px solid #e6e6e6;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 15% auto;
  background: #f7f7f7;
}

.renderRecoverIcon{
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

@media screen and (max-width:1300px) {
  .notificationTopMenu, .allNotificationContainer,.NotificationSubHeading{
    padding: 10px 20px;
  }
  .renderMarkAsReadTop{
    padding: 27px 20px;
  }
}
  *{
    font-family: sans-serif;
}
 .fullbottom > .MuiDrawer-paperAnchorBottom {
    width: 100%;
    display: flex;
    position: fixed;
    max-width: auto;
    bottom: 0px;
    top: 0px !important;
    right: 0px;
    left: auto
  }
   .halfbottom > .MuiDrawer-paperAnchorBottom {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    display: flex;
    position: fixed;
    max-width: auto;
    bottom: 0px;
    top: 200px !important;
    right: 0px;
    left: auto
  }
  .MuiDrawer-paperAnchorRight {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    max-width: 500px;
    left: auto
  }
  @media (max-width:960px) {
    .MuiDrawer-paperAnchorRight {
        max-width: 500px
      }
  }
.bottomBorder{
    height: 7px;
    width:82%;
    margin-left: 9%;
    border-radius: 0px 12px 12px 24px;
    margin-bottom: 2px;
    padding-bottom: 0px;
    background:  var(--secondary-green, #2BCE8C);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    animation-name: example;
    animation-duration :3.5s
}

@keyframes example {
    0%   {width:0%}
    100% {width: 82%}
}

/* .image-rotation{
    animation: rotateImg 1.8s linear infinite;
}

@keyframes rotateImg{
    from {
     transform: rotate(0deg);
    }
   to {
    transform: rotate(359deg);
   }
  } */
