 .fullbottom > .MuiDrawer-paperAnchorBottom {
    width: 100%;
    display: flex;
    position: fixed;
    max-width: auto;
    bottom: 0px;
    top: 0px !important;
    right: 0px;
    left: auto
  }
   .halfbottom > .MuiDrawer-paperAnchorBottom {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    display: flex;
    position: fixed;
    max-width: auto;
    bottom: 0px;
    top: 200px !important;
    right: 0px;
    left: auto
  }
  .MuiDrawer-paperAnchorRight {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    max-width: 500px;
    left: auto
  }
  @media (max-width:960px) {
    .MuiDrawer-paperAnchorRight {
        max-width: 500px
      }
  }