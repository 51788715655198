.HeaderMain{
  width: 100%;
}

.notificationTop {
  width: 100%;
  height: 111px;
  border-radius: 0px 0px 20px 0px;
  background: #01aeff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notificationTopContent {
  width: 100%;
  height: 45px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0.176px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.notificationArrowIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  position: absolute;
  left: 38.3px;
  cursor: pointer;
}

.notificationTitle {
  margin: 0px;
  width: min-content;
  height: 45px;
  flex-shrink: 0;
  color: var(--primary-blue-white, #fff);
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px; /* 118.182% */
  letter-spacing: 0.176px;
}

.notificationTopBgImage {
  position: absolute;
  bottom: 0px;
  right: 5%;
}

.footerMain {
  width: 393px;
  height: 69px;
  display: flex;
  justify-content: space-evenly;
  border-radius: 20px 20px 0px 0px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.08);
}
