.deleteNotification {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.InappDeleteNotification {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationTopMenuListDelete {
  position: absolute;
  width: 223px;
  height: 111px;
  border-radius: 10px;
  border: 2px solid #ccc;
  background: #fff;
  top: 40px;
  right: 12px;
  padding: 15.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 0px;
  z-index: 999;
}

.notificationTopMenuListDeleteItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 9.5px;
  cursor: pointer;
}

.notificationTopMenuListDeleteItemImage {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.notificationTopMenuListDeleteItemTitle {
  width: 100%;
  height: 13px;
  color: #7f7f7f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0px;
}

.allNotificationContainer {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
  scrollbar-width: none;
}

.deleteNotificationCard {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  display: flex;
  border-radius: 10px;
  gap: 19px;
  align-items: center;
  margin: 0px auto 8px auto;
  background: #f2f2f2;
}

.deleteNotificationCardDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.deleteNotificationCardImage{
  width:29px;
  height: 29px;
}

.deleteNotificationHeading {
  color: #7f7f7f;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.deleteNotificationPara {
  color: #7f7f7f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-family: "Quicksand", sans-serif;
}

.deleteNotificationTime {
  font-size: 14px;
  font-weight: 500px;
  font-family: "Quicksand", sans-serif;
  color: #b3b3b3;
}

.NotificationSubHeading {
  width: 100%;
  height: 47px;
  flex-shrink: 0;
  color: #7f7f7f;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin: 0px auto 30px auto;
  padding: 0px 47px;
}

.cardCheckbox.MuiIconButton-colorSecondary {
  stroke: #e6e6e6 !important;
  stroke-width: 1 !important;
}

.cardCheckbox.MuiCheckbox-colorSecondary.Mui-checked {
  stroke: #36bfff !important;
  stroke-linejoin: round !important;
  stroke-linecap: round !important;
  stroke-width: 2 !important;
  color: white !important;
}

.modalDeleteBtn {
  margin-right: 26px;
  width: 110px;
  height: 40px;
  border-radius: 12px;
  background: #f96855;
  color: #fff;
}

.deleteModalCancelBtn {
  margin-left: 26px;
  width: 110px;
  height: 44px;
  border-radius: 12px;
  border: 2px solid #ccc;
  background: #fff;
  color: #cccccc;
}

.deleteModalBtnContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
}

.deleteModalWarningText {
  width: 222px;
  height: 40px;
  color: #666;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  margin-top: 10px;
}

.deleteModalTitle {
  width: 260px;
  height: 19px;
  color: #363636;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin: 17px auto 0px auto;
}

.deleteModalImage {
  width: 39.153px;
  height: 39.153px;
}

.deleteModalInnerBox {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.deleteModalBox {
  width: 292px;
  height: 281px;
  border-radius: 12px;
  border: 2px solid #e6e6e6;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 15% auto;
  background: #f7f7f7;
}

.renderRecoverIcon{
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

@media screen and (max-width:1300px) {
  .notificationTopMenu, .allNotificationContainer,.NotificationSubHeading{
    padding: 10px 20px;
  }
  .renderMarkAsReadTop{
    padding: 27px 20px;
  }
}