.firstLoginTop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.LeftArrow {
  position: absolute;
  left: 0px;
}
.firstLoginlogo {
  width: 100px;
  height: 31.822px;
}
.firstLogintitleContainer {
  margin-top: 35px;
}
.firstLogintitle {
  color: #363636;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  font-family: 'Nunito', sans-serif;
}
.firstLoginSubtitle {
  color: #666666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 10px;
}
.firstLoginDropDownBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 80px auto 0px auto;

}
.firstLoginDropDownBtn {
  width: 260px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #36bfff;
  color: white;
  font-family: 'Nunito', sans-serif;
  border: none;
  font-size: 16px;
  box-shadow: 0px 3px 0px 3px #01AEFF;
  font-weight: 700;
}
.selectAvatar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.avatarSelection {
  position: relative;
  border-radius: 50%;
  width: 260px;
  height: 260px;
  margin: 79px auto 0px auto;
}

.circle {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  height: 58px;
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.circleActive {
  border-radius: 10px;
  background-color: #f2f2f2;
}

.mainImage {
  width: 105.206px;
  height: 133px;
}

.circle:nth-child(1) {
  transform: translate(125px, 8px);
}

.circle:nth-child(2) {
  transform: translate(87px, 87px);
}

.circle:nth-child(3) {
  transform: translate(0px, 125px);
}

.circle:nth-child(4) {
  transform: translate(-87px, 87px);
}

.circle:nth-child(5) {
  transform: translate(-134px, 0px);
}
.circle:nth-child(6) {
  transform: translate(-110px, -80px);
}
.circle:nth-child(7) {
  transform: translate(0px, -130px);
}
.circle:nth-child(8) {
  transform: translate(110px, -75px);
}
.circle:nth-child(9) {
  transform: translate(-22px, -38px);
}
.countryName{
  font-family: 'Nunito', sans-serif;
}