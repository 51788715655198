  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
  @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");
  @import url('../../blocks//email-account-login/src/FirstLogin.web.css');
  @import url('../../blocks//email-account-login/src/FirstLoginSelectAvatar.web.css');
  @import url('../../blocks//notifications/src/InAppNotificationMain.web.css');
  @import url('../../blocks//notifications/src/InAppNotificationHeaderFooter.css');
  @import url('../../blocks//notifications/src/InAppNotificationDetails.web.css');
  @import url('../../blocks//notifications/src/InAppDeleteNotification.web.css');




*{
    font-family: sans-serif;
}