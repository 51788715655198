.bottomBorder{
    height: 7px;
    width:82%;
    margin-left: 9%;
    border-radius: 0px 12px 12px 24px;
    margin-bottom: 2px;
    padding-bottom: 0px;
    background:  var(--secondary-green, #2BCE8C);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    animation-name: example;
    animation-duration :3.5s
}

@keyframes example {
    0%   {width:0%}
    100% {width: 82%}
}

/* .image-rotation{
    animation: rotateImg 1.8s linear infinite;
}

@keyframes rotateImg{
    from {
     transform: rotate(0deg);
    }
   to {
    transform: rotate(359deg);
   }
  } */